import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import UserTable from "../../components/UserTable";
import Filters from "../../components/Filters";
import Order from "../../components/Order";
import ProfileDialog from "../../components/Dialogs/ProfileDialog";
import SelectionOptions from "../../components/SelectionOptions";
import DeleteUserDialog from "../../components/Dialogs/DeleteUserDialog";
import { useIntl } from "react-intl";
import { ACTIVE } from "../../utils/constants";
import {
  useUser,
  BasicBreadcrumbs,
  BasicScreenHeader,
  useLoadingGif,
  TenantIdContext,
  useSnackbar,
  LOCALES,
  exportToExcel,
  DarkModeContext,
} from "@datwyler/shared-components";

const filterDefaults = {
  roles: [],
  statuses: [ACTIVE],
  company: null,
};

const User = () => {
  const { tenantId }: any = useContext(TenantIdContext);
  const { LoadingGif, setIsLoading } = useLoadingGif();
  const [filters, setFilters] = useState(filterDefaults);
  const [order, setOrder] = useState("status");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [dialogOpened, setDialogOpened] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  const {
    isFetchUsersLoading,
    fetchUsersData,
    fetchUsers,
    addUser,
    isAddUserLoading,
    addUserResponseData,
    resetAddUserData,
    updateUser,
    isUpdateUserLoading,
    updateUserResponseData,
    resetUpdateUserData,
  } = useUser();

  const {
    isFetchUsersLoading: isFetchUsersForExportLoading,
    fetchUsersData: fetchUsersForExportData,
    fetchUsers: fetchUsersForExport,
  } = useUser();

  useEffect(() => {
    setIsLoading(
      isFetchUsersLoading ||
        isUpdateUserLoading ||
        isAddUserLoading ||
        isFetchUsersForExportLoading ||
        false
    );
  }, [
    isFetchUsersLoading,
    isUpdateUserLoading,
    isAddUserLoading,
    isFetchUsersForExportLoading,
  ]);

  useEffect(() => {
    if (
      updateUserResponseData?.updateUser &&
      dialogOpened === "reactivateUser"
    ) {
      enqueueSnackbar(
        intl.formatMessage({ id: "update_user_profile_success" }),
        {
          variant: "success",
        }
      );
    }
  }, [updateUserResponseData]);

  useEffect(() => {
    refetchUsers();
  }, [tenantId, filters, page, rowsPerPage, order]);

  const getFilters = () => {
    const filtersToSend = [];

    if (filters.statuses.length > 0) {
      let statuses = "";
      filters.statuses.forEach((status, index) => {
        if (index === 0) statuses = statuses + `status:` + status;
        else statuses = statuses + `|status:` + status;
      });
      filtersToSend.push(statuses);
    }

    if (filters.roles.length > 0) {
      let roles = "";
      filters.roles.forEach((role, index) => {
        if (index === 0) roles = roles + `role:` + role;
        else roles = roles + `|role:` + role;
      });
      filtersToSend.push(roles);
    }

    if (filters.company) {
      filtersToSend.push(`company:${filters.company}`);
    }

    return filtersToSend;
  };

  const getSort = () => {
    let sort = "";

    if (order) {
      switch (order) {
        case "name":
          sort = `firstName,asc`;
          break;
        case "status":
          sort = `status,asc`;
          break;
        case "role":
          sort = `role,asc`;
          break;
        default:
          sort = `status,asc`;
      }
    }

    return sort;
  };

  const refetchUsers = () => {
    const filtersToSend = getFilters();
    const sort = getSort();

    fetchUsers({
      variables: {
        tenantId: tenantId,
        page: { number: page, size: rowsPerPage },
        sort: [sort],
        filter: filtersToSend,
      },
    });
  };

  useEffect(() => {
    if (fetchUsersData?.users?.page) {
      setTotalRows(fetchUsersData.users.page.totalElements || 0);
    }
  }, [fetchUsersData]);

  useEffect(() => {
    if (fetchUsersForExportData?.users?.users) {
      exportToExcel(
        getExcelData(),
        intl.formatMessage({ id: "excel_filename_user" })
      );
    }
  }, [fetchUsersForExportData]);

  const getLowerComponent = () => {
    if (selectedUsers?.length > 0) {
      return <SelectionOptions setDialogOpened={setDialogOpened} />;
    } else {
      return (
        <>
          <Divider sx={{ borderColor: colors.dividerColor }} />
          <Box sx={{ display: "inline-flex" }}>
            <Filters setFilters={setFilters} filterDefaults={filterDefaults} />
          </Box>
          <Box
            sx={{
              display: "inline-flex",
              verticalAlign: "top",
              paddingTop: "16px",
            }}
          >
            <Order order={order} setOrder={setOrder} />
          </Box>
        </>
      );
    }
  };

  const handleAddUser = () => {
    setSelectedUsers([]);
    setDialogOpened("addUser");
  };

  const handleCloseDialog = () => {
    setDialogOpened("");
    setSelectedUsers([]);
  };

  const fetchDataForExcel = () => {
    const filtersToSend = getFilters();
    const sort = getSort();

    fetchUsersForExport({
      variables: {
        tenantId: tenantId,
        filter: filtersToSend,
        sort: [sort],
        page: { number: 0, size: 999999 },
      },
    });
  };

  const getExcelData = () => {
    const excelData = fetchUsersForExportData?.users?.users?.map((data) => {
      const row = {};

      row[intl.formatMessage({ id: "first_name" })] = data.firstName || "-";
      row[intl.formatMessage({ id: "last_name" })] = data.lastName || "-";
      row[intl.formatMessage({ id: "id" })] = data.id || "-";
      row[intl.formatMessage({ id: "email" })] = data.email || "-";
      row[intl.formatMessage({ id: "status" })] = data.status
        ? intl.formatMessage({ id: data.status.toLowerCase() })
        : "-";
      row[intl.formatMessage({ id: "role" })] = data.role
        ? intl.formatMessage({ id: data.role })
        : "-";
      row[intl.formatMessage({ id: "company" })] = data.company || "-";

      return row;
    });
    return excelData;
  };

  return (
    <Box sx={{ paddingTop: "76px", paddingLeft: "24px", paddingRight: "24px" }}>
      <LoadingGif />
      <BasicBreadcrumbs activePage={"User"} />
      <BasicScreenHeader
        title={intl.formatMessage({ id: "menu_user" })}
        addButton={{
          label: intl.formatMessage({ id: "add_user" }),
          onClick: handleAddUser,
          width:
            intl.locale === LOCALES.ENGLISH || intl.locale === LOCALES.CHINESE
              ? "128px"
              : "210px",
        }}
        exportButton={{
          label: intl.formatMessage({ id: "export" }),
          onClick: fetchDataForExcel,
        }}
        LowerComponent={getLowerComponent()}
      />
      <UserTable
        userData={fetchUsersData?.users?.users}
        setSelectedUsers={setSelectedUsers}
        selectedUsers={selectedUsers}
        setDialogOpened={setDialogOpened}
        updateUser={updateUser}
        updateUserResponseData={updateUserResponseData}
        resetUpdateUserData={resetUpdateUserData}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalRows={totalRows}
        tenantId={tenantId}
      />
      <ProfileDialog
        isOpen={dialogOpened == "addUser" || dialogOpened === "updateUser"}
        closeDialog={handleCloseDialog}
        refetchUsers={refetchUsers}
        tenantId={tenantId}
        userProfile={selectedUsers?.length > 0 ? selectedUsers[0] : null}
        addUser={addUser}
        addUserResponseData={addUserResponseData}
        resetAddUserData={resetAddUserData}
        updateUser={updateUser}
        updateUserResponseData={updateUserResponseData}
        resetUpdateUserData={resetUpdateUserData}
      />
      <DeleteUserDialog
        isOpen={dialogOpened == "deactivateUser"}
        closeDialog={handleCloseDialog}
        selectedUsers={selectedUsers}
        refetchUsers={refetchUsers}
        tenantId={tenantId}
        updateUser={updateUser}
        updateUserResponseData={updateUserResponseData}
        resetUpdateUserData={resetUpdateUserData}
      />
    </Box>
  );
};

export default User;
