import { useEffect, useContext } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { useIntl } from "react-intl";
import { DEACTIVATED } from "../../utils/constants";
import {
  CloseIcon24p,
  BasicButton,
  DarkModeContext,
  useSnackbar,
} from "@datwyler/shared-components";

const DeleteUserDialog = (props) => {
  const {
    isOpen = false,
    closeDialog,
    selectedUsers,
    refetchUsers,
    updateUser,
    updateUserResponseData,
    resetUpdateUserData,
    tenantId,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);

  useEffect(() => {
    if (updateUserResponseData?.updateUser?.user && isOpen) {
      enqueueSnackbar(intl.formatMessage({ id: "user_deactivated_success" }), {
        variant: "warning",
      });

      resetUpdateUserData();
      refetchUsers();
      handleClose();
    }
  }, [updateUserResponseData]);

  const handleClose = () => {
    closeDialog();
  };

  const handleConfirmDelete = async () => {
    await Promise.all(
      selectedUsers.map((user) => {
        const submitValues = {
          tenant: { id: tenantId },
          id: user.id,
          firstName: user?.firstName,
          lastName: user?.lastName,
          email: user?.email,
          role: user?.role,
          status: DEACTIVATED,
        };

        updateUser({
          variables: { input: submitValues },
        });
      })
    ).then((allResponses) => {
      enqueueSnackbar(intl.formatMessage({ id: "user_deactivated_success" }), {
        variant: "info",
      });
      closeDialog();
    });
  };

  return (
    <Dialog
      maxWidth={"sm"}
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: { borderRadius: "8px", backgroundColor: colors.cardBg },
      }}
    >
      <DialogTitle sx={{ paddingTop: "26px", paddingBottom: "16px" }}>
        <Typography
          sx={{
            fontFamily: "NotoSans-SemiBold",
            fontSize: 16,
            color: colors.subTitle,
          }}
        >
          {intl.formatMessage({ id: "deactivate_user" })}
        </Typography>
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", right: 16, top: 16 }}
        >
          <CloseIcon24p fill={colors.iconColor} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ fontSize: 14, paddingTop: 1, width: 376 }}>
          <Typography
            sx={{
              fontFamily: "NotoSans-Regular",
              fontSize: 14,
              color: colors.fontTitle,
            }}
          >
            {intl.formatMessage({ id: "confirm_deactivate" })}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{ justifyContent: "flex-start", paddingBottom: "24px" }}
      >
        <Box sx={{ marginLeft: "16px" }}>
          <BasicButton onClick={handleClose} variant={"outlined"}>
            {intl.formatMessage({ id: "cancel" })}
          </BasicButton>
        </Box>
        <Box sx={{ position: "absolute", right: "24px" }}>
          <BasicButton
            onClick={handleConfirmDelete}
            variant={"contained"}
            sx={{
              backgroundColor: colors.red500,
              ":hover": {
                backgroundColor: colors.red800,
              },
            }}
          >
            {intl.formatMessage({ id: "deactivate" })}
          </BasicButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteUserDialog;
