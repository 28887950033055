import { Route, Routes } from "react-router-dom";
import Profile from "./containers/profile";
import User from "./containers/User";
import { CommonStyles, withPermission } from "@datwyler/shared-components";

const UserComponent = () => (
  <CommonStyles>
    <User />
  </CommonStyles>
);
const ProtectedUser = withPermission("user")(UserComponent);
const Router = (props: any) => {
  return (
    <Routes>
      <Route path="/user" element={<ProtectedUser />} />
      <Route
        path="/user/profile"
        element={
          <CommonStyles>
            <Profile />
          </CommonStyles>
        }
      />
      <Route
        path="/smart-building/user/profile"
        element={
          <CommonStyles>
            <Profile />
          </CommonStyles>
        }
      />
    </Routes>
  );
};

export default Router;
